.contact-app{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;  
    
    min-height: 963px;
    height: 100%;
    width: 85%;

}
.contact-upper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;         
}
.contact-href{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;     
}

.contact-arrow {
    max-width: 80%; 
    height: auto; 
    display: block; 
    transition: transform 0.5s ease-in-out;
}
.contact-href:hover .contact-arrow {
    transform: rotate(410deg);
}
.contact-title-image{
    max-width: 100%; 
    height: auto; 
    display: block; 
}
@media screen and (max-width: 500px) {


}