.app-nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 85%;
    margin-top: 25px;
}
.app-nav-links{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 43%;
}
.button-nav-1{
    color: #000;
    /* hero-nav */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;    

    background: none;
    border: none;
    cursor: pointer;
}

.button-nav-2{
    color: rgba(151, 151, 151, 0.50);
    /* hero-nav */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; 
    
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}
.button-nav-2:hover {
    color: #000;
}
.button-nav-3{
    color: #F4F4E6;
    /* hero-nav */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-radius: 50px;
    background: #D48550; 
    height: 62px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid black;
    cursor: pointer;
    text-decoration: none;
    transition: background 0.25s ease-in, color 0.25s ease-in;
}

.button-nav-3:hover {
    background: #282A36;
    color: #D48550;
}
@media screen and (max-width: 500px) {
    .app-nav-links{
        display: none;

    }

}