.home-app{
    background-color: #282A36;
}

.nav-hero-app{
    background-image: url('../assets/home-bg.png');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}

.home-nav{
    height: 10%;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.home-hero{
    height: 90%;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.home-about {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;    
}

.home-project {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.home-contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;   
    margin-top: 18vh; 
}